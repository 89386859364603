export const FaqList = [
  {
    id: "headingOne",
    controls: "collapseOne",
    expanded: true,
    btnClass: "",
    contentClass: "show",
    title: "How can I get a quote?",
    details: `To receive a quote, simply send us an email through our online mail system or give us a call and leave a detailed message. Be sure to include the type of service you need and any specific requirements. A member of our team will get in touch with you promptly to provide all the necessary information and discuss your needs. We’re here to help make the process easy and straightforward!`,
  },
  {
    id: "headingTwo",
    controls: "collapseTwo",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "Can I get a same day booking?",
    details: `Yes, we offer same-day booking! If you need our services on short notice, please contact us as early as possible, and we will do our best to accommodate your request. Your satisfaction is our priority.`,
  },
  {
    id: "headingThree",
    controls: "collapseThree",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "What services are included in standard  Janitorial services ?",
    details: `Our standard janitorial services include a range of essential tasks designed to keep your space clean and inviting. This typically encompasses sweeping and mopping floors, dusting surfaces, and vacuuming carpets. We strive to ensure that every corner of your facility is maintained to the highest standards, creating a fresh and healthy environment for everyone. If you have specific needs or additional requests, please feel free to let us know!`,
  },
  {
    id: "headingFour",
    controls: "collapseFour",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "Do you provide the supplies for your services ?",
    details: `Yes, we provide all the necessary supplies for our services! Our team comes equipped with eco-friendly cleaning products and tools to ensure a thorough and effective cleaning experience. You can rest assured that we have everything needed to maintain a clean and healthy environment for your space. If you have any specific preferences or requirements, please let us know, and we’ll be happy to accommodate you!`,
  },
  {
    id: "headingFive",
    controls: "collapseFive",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "How many cleaners do you offer per service ?",
    details: `The number of cleaners we provide depends on the size and scope of the job. We assess each location and the specific cleaning tasks required to determine the appropriate staffing level. Our goal is to ensure efficient and thorough service tailored to your needs. If you have any specific requests or concerns, please feel free to discuss them with us!`,
  },
];
